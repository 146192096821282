<template>
  <div class="loginPage">
    <HeadInfo :is-login="true" />
    <div class="loginMain">
      <div class="loginForget">
        <!-- <div>
          <img
            :src="`${imgUrl}/anon/system/forgot-image`"
            style="max-width: 216px; max-height: 150px"
            alt="forgotuser"
            :style="visibility"
          />
        </div> -->
        <div>
          <!-- <div class="loginTitle">
            {{ $g("forgetPassword") }}
          </div> -->
          <div class="loginDescription" v-html="description" />
        </div>
      </div>
      <div class="loginContent">
        <k-alert-msg ref="lblMsg" />
        <KValidationObserver ref="observer">
          <k-form-group
            label-class="require-mark"
            label-suffix="userName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, max: 256 }"
              v-slot="{ errors }"
              slim
              name="userName"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="userName"
                v-model="form.userName"
                :aria-label="$g('userName')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <div class="alert alert-warning" v-if="isManMachine">
              {{ $g("validate.required") }}
            </div>
            <ReCaptcha
              @validate="validate"
              @expired="resetCaptcha"
              style="margin-bottom: 25px"
            />
          </k-form-group>
        </KValidationObserver>

        <k-form-group
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapBtnCenter"
        >
          <k-button type="button" @click="next"> Next </k-button>
        </k-form-group>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>
<script>
import { sendUserName, getPageDesc } from "@/api/login";
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import {
  storeage,
  ReCaptcha,
  repResult,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  globalEnv,
} from "fe-infrastractures";
export default {
  components: {
    HeadInfo,
    FooterInfo,
    ReCaptcha,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    let visibility = getConfigVal(
      curPageConfigAndDefaultPageConfig,
      "showPic",
      "vVisibility"
    );
    return {
      visibility,
      isManMachine: false,
      isValidateRecaptcha: false,
      description: "",
      pageName: "ForgotPassword-SendUrl",
      form: {
        userName: "",
      },
      localEnv: storeage.getSystemGeneralInfo()?.isLocal,
      imgUrl: globalEnv.VUE_APP_STD_URL + "/" + globalEnv.VUE_APP_SYSTEM_PATH,
    };
  },
  methods: {
    validate(success) {
      this.isValidateRecaptcha = success;
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
      }
    },
    resetCaptcha() {
      this.isValidateRecaptcha = false;
    },
    async next() {
      const isValid = await this.validateBeforeSave();
      if (isValid) {
        sendUserName(this.form).then((resp) => {
          if (resp.data.status == repResult.success) {
            this.$router.push({
              name: "send-url-success",
            });
          } else {
            this.$refs.lblMsg.message(repResult.faild, resp.message);
          }
        });
      }
    },
    async validateBeforeSave() {
      var isValid = await this.$refs.observer.validate();
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
        isValid = isValid && this.isValidateRecaptcha;
      }
      return isValid;
    },
  },
  mounted() {
    getPageDesc(this.pageName).then((resp) => {
      this.description = resp.data.description;
    });
  },
};
</script>s
